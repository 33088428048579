import React, { useState } from "react";
//COMPONENT
import { Popover, Slider } from "antd";
import { ChromePicker } from "react-color";
import ColorPickerPopup from "./ColorPickerPopup";

//REDUX
import { useDispatch, useSelector } from "react-redux";
import { getListSavedColorSelector } from "@Store/draw/selectors";
import {
  setBrushColor,
  setForceClosePopOver,
  setListSavedColor,
} from "@Store/draw";
//ASSETES
import WhitePage from "@assets/images/PageBackground/white.png";
import BlackPage from "@assets/images/PageBackground/black.png";
import GreenPage from "@assets/images/PageBackground/green.png";
import TransparentPage from "@assets/images/PageBackground/transp.png";
import ImagePage from "@assets/images/PageBackground/image.png";
import ImageHalfPage from "@assets/images/PageBackground/image-half.png";

import DotPage from "@assets/images/PageBackground/dot.png";
import LinePage from "@assets/images/PageBackground/line.png";
import CaroPage from "@assets/images/PageBackground/caro.png";
import ColorPickImage from "@assets/images/color-picker.png";

import { BOARD_BACKGROUND_TYPE } from "@constants";
import useBoardManager from "@Hooks/useBoardManager";
import CustomPopover from "../../../CustomPopover";
import { colorObjectToRGBAString } from "@utils/color";
import { getCurrentBoardDataSelector } from "@Store/board/selectors";

import PageBackgroundHalf1 from "@assets/images/page-background/half/1.png";
import PageBackgroundHalf2 from "@assets/images/page-background/half/2.png";
import PageBackgroundHalf3 from "@assets/images/page-background/half/3.png";
import PageBackgroundHalf4 from "@assets/images/page-background/half/4.png";
import PageBackgroundHalf5 from "@assets/images/page-background/half/5.png";
import PageBackgroundHalf6 from "@assets/images/page-background/half/6.png";

import PageBackgroundWide1 from "@assets/images/page-background/wide/1.png";
import PageBackgroundWide2 from "@assets/images/page-background/wide/2.png";
import PageBackgroundWide3 from "@assets/images/page-background/wide/3.png";
import PageBackgroundWide4 from "@assets/images/page-background/wide/4.png";
import PageBackgroundWide5 from "@assets/images/page-background/wide/5.png";
import PageBackgroundWide6 from "@assets/images/page-background/wide/6.png";
import PageBackgroundWide7 from "@assets/images/page-background/wide/7.png";
import PageBackgroundWide8 from "@assets/images/page-background/wide/8.png";

const OPT1 = [
  {
    type: BOARD_BACKGROUND_TYPE.SOLID,
    color: "#ffffff",
    image: WhitePage,
  },
  {
    type: BOARD_BACKGROUND_TYPE.SOLID,
    color: "#108804",
    image: GreenPage,
  },
  {
    type: BOARD_BACKGROUND_TYPE.SOLID,
    color: "#000000",
    image: BlackPage,
  },
  {
    type: BOARD_BACKGROUND_TYPE.SOLID,
    color: "#FFFFFF00",
    image: TransparentPage,
  },
  // {
  //   type: BOARD_BACKGROUND_TYPE.IMAGE,
  //   color: "#FFFFFF00",
  //   image: ImagePage,
  // },
];

const OPT2 = [
  {
    type: BOARD_BACKGROUND_TYPE.SOLID,
    color: "#ffffff",
  },
];

const OPT4 = [
  {
    type: BOARD_BACKGROUND_TYPE.IMAGE,
    image: PageBackgroundHalf1,
  },
  {
    type: BOARD_BACKGROUND_TYPE.IMAGE,
    image: PageBackgroundHalf2,
  },
  {
    type: BOARD_BACKGROUND_TYPE.IMAGE,
    image: PageBackgroundHalf3,
  },
  {
    type: BOARD_BACKGROUND_TYPE.IMAGE,
    image: PageBackgroundHalf4,
  },
  {
    type: BOARD_BACKGROUND_TYPE.IMAGE,
    image: PageBackgroundHalf5,
  },
  {
    type: BOARD_BACKGROUND_TYPE.IMAGE,
    image: PageBackgroundHalf6,
  },
];

const OPT5 = [
  {
    type: BOARD_BACKGROUND_TYPE.IMAGE,
    image: PageBackgroundWide1,
  },
  {
    type: BOARD_BACKGROUND_TYPE.IMAGE,
    image: PageBackgroundWide2,
  },
  {
    type: BOARD_BACKGROUND_TYPE.IMAGE,
    image: PageBackgroundWide3,
  },
  {
    type: BOARD_BACKGROUND_TYPE.IMAGE,
    image: PageBackgroundWide4,
  },
  {
    type: BOARD_BACKGROUND_TYPE.IMAGE,
    image: PageBackgroundWide5,
  },
  {
    type: BOARD_BACKGROUND_TYPE.IMAGE,
    image: PageBackgroundWide6,
  },
  {
    type: BOARD_BACKGROUND_TYPE.IMAGE,
    image: PageBackgroundWide7,
  },
  {
    type: BOARD_BACKGROUND_TYPE.IMAGE,
    image: PageBackgroundWide8,
  },
];
const OPT3 = [
  {
    type: BOARD_BACKGROUND_TYPE.SOLID,
    color: "rgb(255, 255, 255)",
  },
  {
    type: BOARD_BACKGROUND_TYPE.SOLID,
    color: "rgb(242, 242, 242)",
  },
  {
    type: BOARD_BACKGROUND_TYPE.SOLID,
    color: "rgb(102, 102, 102)",
  },
  {
    type: BOARD_BACKGROUND_TYPE.SOLID,
    color: "rgb(46, 46, 46)",
  },
  {
    type: BOARD_BACKGROUND_TYPE.SOLID,
    color: "rgb(0, 0, 0)",
  },
  {
    type: BOARD_BACKGROUND_TYPE.SOLID,
    color: "rgb(231, 247, 253)",
  },
  {
    type: BOARD_BACKGROUND_TYPE.SOLID,
    color: "rgb(230, 234, 254)",
  },
  {
    type: BOARD_BACKGROUND_TYPE.SOLID,
    color: "rgb(240, 232, 243)",
  },
  {
    type: BOARD_BACKGROUND_TYPE.SOLID,
    color: "rgb(249, 227, 230)",
  },
  {
    type: BOARD_BACKGROUND_TYPE.SOLID,
    color: "rgb(252, 246, 220)",
  },
  {
    type: BOARD_BACKGROUND_TYPE.SOLID,
    color: "rgb(236, 245, 229)",
  },
];

export default function BackgroundSettingPopup({
  handleSave = () => {},
  viewMode,
}) {
  const dispatch = useDispatch();
  const { updateBoard } = useBoardManager();
  const [backgroundType, setBackgroundType] = useState(
    BOARD_BACKGROUND_TYPE.SOLID
  );

  return (
    <div className="background-config__popup">
      <div className="label">Model</div>
      <div className="list-default-pattern d-flex flex-row flex-wrap">
        {OPT2.map((item, index) => (
          <div
            key={index}
            className="list-item"
            onClick={() => {
              setBackgroundType(BOARD_BACKGROUND_TYPE.SOLID);
              updateBoard({
                backgroundType: item.type,
              });
            }}
          >
            {item.image ? <img src={item.image} /> : null}
          </div>
        ))}
        {viewMode == "narrow" ? (
          <div
            className="list-item"
            onClick={() => {
              setBackgroundType(BOARD_BACKGROUND_TYPE.IMAGE + "half");
              // updateBoard({ backgroundType: BOARD_BACKGROUND_TYPE.IMAGE });
            }}
          >
            <img src={ImageHalfPage} />
          </div>
        ) : (
          <div
            className="list-item"
            onClick={() => {
              setBackgroundType(BOARD_BACKGROUND_TYPE.IMAGE + "wide");
              // updateBoard({ backgroundType: BOARD_BACKGROUND_TYPE.IMAGE });
            }}
          >
            <img src={ImagePage} />
          </div>
        )}
      </div>
      {backgroundType == BOARD_BACKGROUND_TYPE.SOLID ? (
        <>
          <div className="label">Arka plan</div>
          <div className="list-default-color d-flex flex-row flex-wrap">
            {OPT3.map((item, index) => (
              <div key={index} className="list-item">
                {item.image ? (
                  <CustomPopover
                    placement="topRight"
                    trigger="click"
                    overlayClassName="color-popup"
                    content={
                      <ColorPickerPopup
                        onSubmit={(color) => {
                          updateBoard({
                            backgroundColor: color,
                          });
                        }}
                      />
                    }
                  >
                    <img src={item.image} />
                  </CustomPopover>
                ) : (
                  <div
                    onClick={() => {
                      updateBoard({
                        backgroundColor: item.color,
                      });
                    }}
                    className="list-item__inner"
                    style={{ backgroundColor: item.color }}
                  />
                )}
              </div>
            ))}
          </div>
        </>
      ) : backgroundType.includes("half") ? (
        <>
          <div className="label">Arka plan</div>
          <div className="list-default-color d-flex flex-row flex-wrap">
            {OPT4.map((item, index) => (
              <div
                key={index}
                className="list-item"
                onClick={() => {
                  updateBoard({
                    boardImage: item.image,
                    backgroundType: BOARD_BACKGROUND_TYPE.IMAGE,
                  });
                }}
              >
                {item.image ? <img src={item.image} /> : null}
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="label">Arka plan</div>
          <div className="list-default-color d-flex flex-row flex-wrap">
            {OPT5.map((item, index) => (
              <div
                key={index}
                className="list-item"
                onClick={() => {
                  updateBoard({
                    boardImage: item.image,
                    backgroundType: BOARD_BACKGROUND_TYPE.IMAGE,
                  });
                }}
              >
                {item.image ? <img src={item.image} /> : null}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
