import React, { useState } from "react";

const ImageBackground = (props) => {
  return (
    <div
      id="background-transform"
      className="background_container"
      style={{
        transformOrigin: "0px 0px",
        width: `100vw`,
        height: `100vh`,
        pointerEvents: "none",
        position: "absolute",
      }}
    >
      <img
        style={{
          position: "relative",
          width: `${props.scale}vw`,
          height: `${(props.scale / 2480) * 3508}vw`,
          objectFit: "cover",
        }}
        src={props?.image}
      />
    </div>
  );
};
export default ImageBackground;
