import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: "20px",
    background: "linear-gradient(145deg, #ffffff, #e6e6e6)",
    boxShadow: "6px 6px 12px #cfcfcf, -6px -6px 12px #ffffff",
    padding: "20px",
    textAlign: "center",
  },
  "& .MuiDialogTitle-root": {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "20px",
  },
  "& .MuiDialogActions-root": {
    justifyContent: "center",
    marginTop: "20px",
  },
}));

const CustomButton = styled(Button)(({ theme }) => ({
  borderRadius: "50px",
  padding: "10px 30px",
  margin: "10px",
  background: "linear-gradient(145deg, #6f85ff, #5c70e0)",
  color: "#fff",
  boxShadow: "3px 3px 8px #5a69d0, -3px -3px 8px #7589ff",
  "&:hover": {
    background: "linear-gradient(145deg, #7589ff, #5c70e0)",
    boxShadow: "none",
  },
  fontWeight: "bold",
}));

const ViewModeModal = ({ open, onClose }) => {
  const handleSelect = (mode) => {
    onClose(mode);
  };

  return (
    <StyledDialog open={open} aria-labelledby="view-mode-modal">
      <Typography variant="h5" color="primary">
        Soru Tipini Seçiniz
      </Typography>
      <DialogContent>
        <Typography variant="body1" color="textSecondary">
          Uygulamayı dar veya geniş modda başlatmak için lütfen
          görünüm modunu seçin.
        </Typography>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={() => handleSelect("narrow")}>Dar</CustomButton>
        <CustomButton onClick={() => handleSelect("wide")}>Geniş</CustomButton>
      </DialogActions>
    </StyledDialog>
  );
};

export default ViewModeModal;
