import React from "react";
//REDUX
import { useSelector } from "react-redux";
import { getIsHideAllUISelector } from "@Store/draw/selectors";
import { getCurrentBoardDataSelector } from "@Store/board/selectors";
//UTIL
import equal from "fast-deep-equal";
//COMPONENT
import DotBackground from "./DotBackground";
import GridBackground from "./GridBackground";
import HorizontalLineBackground from "./HorizontalLineBackground";
import SolidBackground from "./SolidBackground";
import ImageBackground from "./ImageBackground";
//CONSTANT
import { BOARD_BACKGROUND_TYPE } from "@constants";
import { invertColor } from "../../../../utils/color";
import { renderControl } from "../../utils";

const CanvasBackground = ({ scale }) => {
  const boardData = useSelector(getCurrentBoardDataSelector, (prev, next) => {
    const prevProps = {
      type: prev?.backgroundType,
      color: prev?.backgroundColor,
      image: prev?.boardImage,
    };
    const nextProps = {
      type: next?.backgroundType,
      color: next?.backgroundColor,
      image: next?.boardImage,
    };
    console.log();
    return equal(prevProps, nextProps);
  });
  const isHideUI = useSelector(getIsHideAllUISelector);
  const invertedColor = invertColor(
    boardData?.backgroundColor || "#ffffff",
    true
  );

  React.useEffect(() => {
    renderControl(invertedColor);
  }, [invertedColor]);
  if (isHideUI) return null;
  // if (boardData?.backgroundType === BOARD_BACKGROUND_TYPE.DOT) {
  //   return (
  //     <DotBackground
  //       invertedColor={invertedColor}
  //       backgroundColor={boardData?.backgroundColor}
  //       key={BOARD_BACKGROUND_TYPE.DOT}
  //       scale={scale}
  //     />
  //   );
  // }
  // if (boardData?.backgroundType === BOARD_BACKGROUND_TYPE.GRID) {
  //   return (
  //     <GridBackground
  //       invertedColor={invertedColor}
  //       backgroundColor={boardData?.backgroundColor}
  //       key={BOARD_BACKGROUND_TYPE.GRID}
  //       scale={scale}
  //     />
  //   );
  // }
  // if (boardData?.backgroundType === BOARD_BACKGROUND_TYPE.LINE) {
  //   return (
  //     <HorizontalLineBackground
  //       invertedColor={invertedColor}
  //       backgroundColor={boardData?.backgroundColor}
  //       key={BOARD_BACKGROUND_TYPE.LINE}
  //       scale={scale}
  //     />
  //   );
  // }
  if (boardData?.backgroundType === BOARD_BACKGROUND_TYPE.IMAGE) {
    return <ImageBackground image={boardData?.boardImage} scale={scale} />;
  }
  // if (boardData?.backgroundType === BOARD_BACKGROUND_TYPE.SOLID) {
  return (
    <SolidBackground
      backgroundColor={boardData?.backgroundColor}
      scale={scale}
    />
  );
  // }

  // return <GridBackground key={BOARD_BACKGROUND_TYPE.DOT} scale={scale} />;
};
export default CanvasBackground;
