import React, { useState } from "react";

const SolidBackground = (props) => {
  const width = window.screen.width * 4;
  const height = window.screen.height * 4;
  return (
    <div
      className="background_container"
      style={{
        transformOrigin: "0px 0px",
        width: `100vw`,
        height: `100vh`,
        pointerEvents: "none",
        position: "absolute",
      }}
    >
      <div
        style={{
          position: "relative",
          width: `${props.scale}vw`,
          height: `${(props.scale / 2480) * 3508}vw`,
          backgroundColor: props?.backgroundColor || "#fff",
        }}
      ></div>
    </div>
  );
};
export default SolidBackground;
