import { useState, useEffect } from "react";
import "./style.scss";

const RestictAreaOverlay = ({ viewMode, scale }) => {
  const [leftPadding, setLeftPadding] = useState(0);
  const [rightPadding, setRightPadding] = useState(0);
  const [topPadding, setTopPadding] = useState(0);
  const [bottomPadding, setBottomPadding] = useState(0);
  useEffect(() => {
    if (viewMode == "narrow") {
      setLeftPadding((4 / 60) * scale);
      setRightPadding((30 / 60) * scale);
      setTopPadding((10 / 60) * scale);
      setBottomPadding((6 / 60) * scale);
    } else {
      setLeftPadding((4 / 60) * scale);
      setRightPadding((4 / 60) * scale);
      setTopPadding((10 / 60) * scale);
      setBottomPadding((6 / 60) * scale);
    }
  }, [viewMode, scale]);
  return (
    <>
      <div
        className="draw-area"
        style={{
          left: leftPadding + "vw",
          top: topPadding + "vw",
          right: rightPadding + "vw",
          bottom: bottomPadding + "vw",
          height: "auto",
          width: "auto",
        }}
      ></div>
      <div
        className="restrict-area top"
        style={{ height: topPadding + "vw" }}
      ></div>
      <div
        className="restrict-area bottom"
        style={{ height: bottomPadding + "vw" }}
      ></div>
      <div
        className="restrict-area left"
        style={{ width: leftPadding + "vw" }}
      ></div>
      <div
        className="restrict-area right"
        style={{ width: rightPadding + "vw" }}
      ></div>
    </>
  );
};

export default RestictAreaOverlay;
