import React, { useEffect } from "react";
//COMPONENT
import { Popover } from "antd";

//ASSest
import IconImage from "@assets/images/pick-image.png";
import useToggleMode from "@Hooks/useToggleMode";
import classNames from "classnames";
import { ImageIcon } from "@assets/svg";
import { useParams } from "react-router-dom";
import axios from "axios";
import { setImageCropSrc } from "@/Store/draw";
import { useDispatch } from "react-redux";

export default function ImagePicker({ canvas, rotate, rotateLeft }) {
  const idxRef = React.useRef(0);
  const { toggleViewMode } = useToggleMode();

  // const { bookId, pageId } = useParams();
  // const { url } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    window?.electron?.sendToMain("load-image");
    window?.electron?.receiveFromMain("load-image", (data) => {
      if (data.crop) {
        dispatch(setImageCropSrc(data.data));
      }
      else {
        autoloadImage(data.data);
      }
    });
  }, []);

  // useEffect(() => {
  //   const fetchImage = async () => {
  //     if (bookId && pageId) {
  //       const result = await axios.get(
  //         `https://navibook.com.tr/api/book/page-view/${bookId}/${pageId}`
  //       );
  //       if (result.status === 200) {
  //         // autoloadImage(result.data);
  //         // console.log(result.data);
  //         dispatch(setImageCropSrc(result.data));
  //       }
  //     } else if (url) {
  //       const result = await axios.post("/api/get-base64-img", { url: url });
  //       if (result.status === 200) {
  //         // autoloadImage(result.data);
  //         // console.log(result.data);
  //         dispatch(setImageCropSrc(result.data.dataUrl));
  //       }
  //     }
  //   };
  //   fetchImage();
  // }, [bookId, pageId]);

  const autoloadImage = (data) => {
    canvas.current?.addImageToBoard(data, false, 3);

  };
  const handleFileChange = (e) => {
    let file = e.target.files[0];
    var reader = new FileReader();
    reader.onload = function (event) {
      let imageSrc = event.target.result;
      canvas.current?.addImageToBoard(imageSrc, false, 4, idxRef.current);
      e.target.value = "";
      toggleViewMode(true);

      if (idxRef.current > 6) {
        idxRef.current = -1;
        return;
      }
      if (idxRef.current < -6) {
        idxRef.current = 1;
        return;
      }
      if (idxRef.current >= 0) {
        idxRef.current++;
      } else {
        idxRef.current--;
      }
    };
    reader.readAsDataURL(file);
  };

  return (
    <div
      className={classNames("button-toolbar", {
        "rotate-left": rotateLeft && rotate,
        rotate: rotate,
      })}
    >
      <input
        onChange={handleFileChange}
        multiple={false}
        type="file"
        accept="image/*"
        onClick={() => console.log("image")}
      />
      <ImageIcon />
    </div>
  );
}
